import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  if (to.name === 'auth-callback') return

  if (to.name === 'demo-call-callSlug') return

  const authStore = useAuthStore()

  if (['signin'].includes(to.name as string) && authStore.isAuthenticated) {
    console.info('name', to.name)
    console.info('authStore.isAuthenticated', authStore.authUser)
    return navigateTo('/')
  }

  if (
    [
      'signin',
      'simulation-simulationId',
      'activate',
      'test-testId',
      'file-submission-testId',
    ].includes(to.name as string)
  )
    return

  const returnTo = to.path

  if (!authStore.isAuthenticated && to.name !== 'signin') {
    if (returnTo !== '/') return navigateTo('/signin?returnTo=' + returnTo)

    return navigateTo('/signin')
  }
})
