import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '~/stores/auth'
import { type SignUpData, type UserWithWorkspaceSummaries } from '~/types/User'
import type { Candidate } from '~/types/Candidate'
import type { Unsaved } from '~/types/Unsaved'
import { NetworkError } from '~/errors/NetworkError'

export const useUserStore = defineStore('user', () => {
  const { addToast } = useToastNotifications()
  const UserService = userService()
  const router = useRouter()

  // OTHER STORES =============================================================
  const authStore = useAuthStore()

  // STATE ====================================================================

  const user = ref<UserWithWorkspaceSummaries | null>(null)

  const currentAuthor = computed(() => ({
    firstName: user.value?.firstName,
    lastName: user.value?.lastName,
    user: user.value?.id,
  }))

  const isUserEnrolled = computed(() => user.value !== null)

  const isSuperUser = computed(() => user.value?.isSuper ?? false)

  const userCandidateData = computed((): Unsaved<Candidate> | null => {
    if (!user.value) return null

    return {
      identifier: user.value.email,
      firstName: user.value.firstName,
      lastName: user.value.lastName,
      organization: 'Mimicly',
    }
  })

  watch(
    () => user.value?.id,
    (newUserId, oldUserId) => {
      if (newUserId === oldUserId) return

      if (!newUserId) {
        Sentry.setUser(null)
        return
      }

      Sentry.setUser({
        id: newUserId,
      })
    },
    {
      immediate: true,
    },
  )

  // CRUD =====================================================================

  async function completeSignUp(userData: SignUpData, userId: string) {
    const { data, error } = await UserService.updateUserById(userId, userData)

    if (error !== null) {
      return { data, error }
    }

    await $initialise()

    return { data, error }
  }

  function getIsUserInWorkspace(workspaceId: string) {
    if (!user.value) return false

    return user.value.workspaces.some(
      (workspace) => workspace.id === workspaceId,
    )
  }

  // API ======================================================================
  async function fetchUserFromServer(email: string, authServiceId: string) {
    if (!email) {
      const error = Error(
        'Could not fetch user from server. No email provided.',
      )
      Sentry.captureException(error)

      return {
        data: null,
        error,
      }
    }

    if (!authServiceId) {
      const error = Error(
        'Could not fetch user from server. No auth service Id provided.',
      )
      Sentry.captureException(error)

      return {
        data: null,
        error,
      }
    }

    const { data, error } = await UserService.postSignIn(email)

    if (error !== null) {
      return {
        data: null,
        error,
      }
    }

    return {
      data,
      error,
    }
  }

  // META ACTIONS ========================================
  function reset() {
    user.value = null
  }

  const {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $reset,
    $refresh,
  } = useStoreManager(
    computed(() => authStore.authUser?.email),

    async () => {
      if (
        !authStore.isAuthenticated ||
        !authStore.authUser?.email ||
        !authStore.authServiceId
      ) {
        // showError({
        //   statusCode: 500,
        //   statusMessage: 'User could not be initialised. Please sign in again.',
        // })
        return false
      }

      const { data, error } = await fetchUserFromServer(
        authStore.authUser.email,
        authStore.authServiceId,
      )

      if (error !== null) {
        if (error instanceof NetworkError && error.statusCode === 404) {
          showError({
            statusCode: 404,
            statusMessage:
              'You were signed in successfully, but you do not have an account on Mimicly. Please request an invite from a Mimicly admin.',
          })
          return false
        }
        showError(error)
        return false
      }

      if (data.firstName === null && data.lastName === null) {
        router.push(`/signup?id=${data.id}`)
        return false
      }

      user.value = data

      return true
    },

    reset,

    async () => {
      if (!authStore.isAuthenticated || !user.value?.id) return

      const { data, error } = await UserService.getUserById(user.value.id)

      if (error !== null) {
        addToast({
          type: 'error',
          heading: 'Error refreshing user',
          message:
            'The user could not be refreshed. If the problem persists, please try reloading the page.',
          life: 5000,
        })
        return
      }

      user.value = data
    },
  )

  return {
    user,
    currentAuthor,
    $isInitialising,
    $isUpdating,
    $isInitialised,
    isUserEnrolled,
    $ensureInitialised,
    $initialise,
    $reset,
    $refresh,
    completeSignUp,
    userCandidateData,
    getIsUserInWorkspace,
    isSuperUser,
  }
})
