import { httpService } from '~/services/httpService'
import type { User, UserWithWorkspaceSummaries } from '~/types/User'

export function userService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const { getResource, patchResource, deleteResource, postResource } =
    httpService<UserWithWorkspaceSummaries>(serverUrl)

  async function getUsers(queryParameters: Record<string, string> = {}) {
    const response = await getResource<UserWithWorkspaceSummaries[]>('/users', {
      query: queryParameters,
    })
    return response
  }

  async function getUserById(userId: string) {
    const response = await getResource(`/users/${userId}`)
    return response
  }

  async function updateUserById(userId: string, body: Partial<User>) {
    const response = await patchResource(`/users/${userId}`, body)
    return response
  }

  async function createUser(body: Partial<User>) {
    const response = await postResource(`/users`, body)
    return response
  }

  async function deleteUserById(userId: string) {
    const response = await deleteResource<undefined>(`/users/${userId}`)
    return response
  }

  async function postSignIn(email: string) {
    const response = await postResource(`/users/sign-in`, {
      email,
    })
    return response
  }

  async function inviteUser(email: string) {
    const response = await postResource(`/users/sign-up`, {
      email,
    })
    return response
  }

  // async function updateUserRole(userId: string, role: UserRole) {
  //   const response = await patchResource(`/users/${userId}/role`, {
  //     role,
  //   })
  //   return response
  // }

  return {
    getUsers,
    getUserById,
    updateUserById,
    deleteUserById,
    createUser,
    postSignIn,
    inviteUser,
  }
}
