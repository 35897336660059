import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()

  if (
    typeof to.name === 'string' &&
    to.name.includes('system-admin') &&
    !userStore.isSuperUser
  ) {
    return navigateTo('/')
  }
})
